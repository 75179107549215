import errors from "./errors";
import damageType from "./damageType";
import accountType from "./accountType";
import repairType from "./repairType";
import polishType from "./polishType";
import requestType from "./requestType";
import socketEvents from "./socketEvents";
import offerType from "./offerType";
import notificationType from "./notificationType";
import offerStatus from "./offerStatus";
import offerInvalidationReason from "./offerInvalidationReason";
import templates from "./templates";

export default {
  errors,
  damageType,
  accountType,
  repairType,
  polishType,
  requestType,
  socketEvents,
  offerType,
  notificationType,
  offerStatus,
  offerInvalidationReason,

  // adminpanel related
  templates,
};
