import { FETCH_TEMPLATE, PUBLISH_TEMPLATE, SAVE_TEMPLATE, GET_TEMPLATE, UPDATE_CACHE_TEMPLATE } from "./actions";
import { SET_TEMPLATE, SET_ERROR, SET_FETCHING, SET_SENDING } from "./mutations";
import apiService from "../../../services/api";

const state = {
  isFetching: false,
  isSending: false,
  currKey: null,
  currContent: null,
  contentsCache: {},
  error: null
};

const actions = {
  async [UPDATE_CACHE_TEMPLATE](context, { key, content }) {
    if (context.state.contentsCache[key] != null) {
      context.commit(SET_TEMPLATE, { key, content });
    }
  },
  async [GET_TEMPLATE](context, key) {
    if (context.state.contentsCache[key] != null) {
      context.commit(SET_TEMPLATE, { key, content: context.state.contentsCache[key] });
    } else {
      context.dispatch(FETCH_TEMPLATE, key);
    }
  },
  async [FETCH_TEMPLATE](context, key) {
    context.commit(SET_FETCHING, true);
    context.commit(SET_TEMPLATE, { key, content: "" });
    context.commit(SET_ERROR, null);
    try {
      context.commit(SET_TEMPLATE, { key, content: (await apiService.getTemplate(key)).content });
    } catch (error) {
      context.commit(SET_ERROR, error);
    }
    context.commit(SET_FETCHING, false);
  },
  async [PUBLISH_TEMPLATE](context) {
    context.commit(SET_SENDING, true);
    context.commit(SET_ERROR, null);
    try {
      await apiService.publishTemplate(context.state.currKey);
    } catch (error) {
      context.commit(SET_ERROR, error);
    }
    context.commit(SET_SENDING, false);
  },
  async [SAVE_TEMPLATE](context, { key, message }) {
    const content = context.state.contentsCache[key];
    context.commit(SET_SENDING, true);
    context.commit(SET_ERROR, null);
    try {
      await apiService.updateTemplate(key, content, message);
    } catch (error) {
      context.commit(SET_ERROR, error);
    }
    context.commit(SET_SENDING, false);
  }
};

const mutations = {
  [SET_FETCHING](state, status) {
    state.isFetching = status;
  },
  [SET_SENDING](state, status) {
    state.isSending = status;
  },
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_TEMPLATE](state, { key, content }) {
    state.currKey = key;
    state.currContent = content;
    state.contentsCache[key] = content;
  },
};

export default {
  state,
  actions,
  mutations
};
