import Vue from 'vue'
import Element from 'element-ui'
import moment from 'moment'
import App from './App.vue'
import store from "./vuex/store";
import "@firebase/firestore";
import "@firebase/auth"

import 'element-ui/lib/theme-chalk/index.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLayerGroup, faEnvelope, faSync, faRocket, faLink, faHome, faUsers, faShip, faChevronDown, faChevronUp, faAngleRight, faCloud, faLowVision, faEye, faLock, faDolly, faCoins, faServer, faExpand, faCompress, faCarCrash, faFilter, faSortAlphaDown, faSortAlphaUp, faEllipsisV, faFileDownload, faCashRegister, faMoneyCheck, faFilePdf, faClock, faExclamationCircle, faCogs, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faTrello } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from './router'

import auth from "./services/auth";
import api from './services/api';

moment.locale('de')

auth.init();
api.init();

library.add(faHome);
library.add(faUsers);
library.add(faShip);
library.add(faSync);
library.add(faAngleRight);
library.add(faCloud);
library.add(faGithub);
library.add(faLowVision);
library.add(faEye);
library.add(faLock);
library.add(faDolly);
library.add(faCoins);
library.add(faServer);
library.add(faExpand);
library.add(faCompress);
library.add(faLink);
library.add(faCarCrash);
library.add(faFilter);
library.add(faSortAlphaUp);
library.add(faSortAlphaDown);
library.add(faRocket);
library.add(faTrello);
library.add(faEnvelope);
library.add(faEllipsisV);
library.add(faLayerGroup);
library.add(faFileDownload);
library.add(faCashRegister);
library.add(faMoneyCheck);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faFilePdf);
library.add(faClock);
library.add(faExclamationCircle)
library.add(faCogs)
library.add(faCheck);


Vue.component('fa-icon', FontAwesomeIcon)
Vue.use(Element)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
