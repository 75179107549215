<template>
    <div class="menu">
        <div class="logo"></div>
        <div class="entries">
          <div @click="nav('/')" :class="{ entry: 1, selected: currRoute === '/' }">
            <fa-icon icon="home" />
          </div>
          <div @click="nav('/payouts')" :class="{ entry: 1, selected: currRoute === '/payouts' }">
            <fa-icon icon="cash-register" />
          </div>
          <div @click="nav('/users')" :class="{ entry: 1, selected: currRoute === '/users' }">
            <fa-icon icon="users" />
          </div>
          <div @click="nav('/damages')" :class="{ entry: 1, selected: currRoute === '/damages' }">
            <fa-icon icon="car-crash" />
          </div>
          <div @click="nav('/mails')" :class="{ entry: 1, selected: currRoute === '/mails' }">
            <fa-icon icon="envelope" />
          </div>
          <div class="sep"></div>
          <div @click="nav('/links')" :class="{ entry: 1, selected: currRoute === '/links' }">
            <fa-icon icon="link" />
          </div>
          <div @click="nav('/templates')" :class="{ entry: 1, selected: currRoute === '/templates' }">
            <fa-icon icon="layer-group" />
          </div>
          <div @click="nav('/ship')" :class="{ entry: 1, selected: currRoute === '/ship' }">
            <fa-icon icon="ship" />
          </div>
        </div>

        <div class="env-switch">
          <span :class="isProd ? 'prod' : 'stage'">{{ isProd ? 'Prod' : 'Stage' }}</span>
          <el-switch
            style="display: block"
            :value="isProd"
            @change="changeEnv"
            active-color="#ff4949"
            inactive-color="#13ce66">
          </el-switch>
        </div>

        <el-popover
        placement="right"
        width="400"
        trigger="click"
        v-model="userMenuVisible">
        <div class="user-actions">
          <div class="user" >Logged in as <b>{{ authUser && authUser.email }}</b></div>
          <el-button @click="logout" type="danger">Logout</el-button>
        </div>
        <div slot="reference" class="user">
          <div class="online-indicator"></div>
        </div>
      </el-popover>

    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import auth from '../services/auth';
import { CHANGE_ENV } from '../vuex/modules/settings/actions';
export default {
  name: 'Menu',
  data() {
    return {
      userMenuVisible: false,
    };
  },
  computed: {
    ...mapGetters(['authUser']),
    ...mapState({
      env: state => state.settings.env,
    }),
    currRoute() {
      console.log(this.$route);
      return this.$route.path;
    },
    isProd() {
      return this.env === "production";
    }
  },
  methods: {
    nav(url) {
      this.$router.history.push(url);
    },
    logout() {
      auth.logout();
      this.nav('/login')
      this.userMenuVisible = false;
    },
    changeEnv() {
      this.$store.dispatch(CHANGE_ENV, this.isProd ? 'staging' : 'production');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.menu {
  flex-shrink: 0;
  width: 105px;
  background: $cream;
  box-sizing: border-box;
  border-right: 1px solid rgb(226, 226, 226);
  display: flex;
  flex-direction: column;

  .logo {
    background-image: url(../assets/logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 46px;
    height: 46px;
    margin: 40px auto 12px auto;
  }

  .user {
    position: relative;
    cursor: pointer;
    width: 38px;
    height: 38px;
    margin: 20px auto;
    background: white;
    text-align: center;
    border-radius: 50%;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.14);
    border: 4px solid white;
    background-image: url(/img/photo.png);
    background-size: cover;
    background-position: center center;

    .online-indicator {
      position: absolute;
      top: -5px;
      left: -5px;
      border-radius: 50%;
      background: green;
      border: 3px solid white;
      width: 12px;
      height: 12px;
    }
  }

  .entries {
    flex-grow: 1;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    background: rgba(88, 88, 88, 0.1);
    margin: 12px;
    border-radius: 12px;

    .sep {
      width: 30px;
      margin: 0 auto;
      border-top: 2px solid rgba(0, 0, 0, 0.15);
      border-radius: 30px;
    }

    .entry {
      height: 46px;
      width: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px auto;
      border: 2px solid transparent;
      box-sizing: border-box;
      border-radius: 50%;
      color: transparentize($deepsea, 0.6);
      cursor: pointer;

      &:hover {
        border: 2px solid transparentize($deepsea, 0.85);
      }

      &.selected {
        color: $deepsea;
        background: transparentize($deepsea, 0.85);

        &:hover {
          border: 2px solid transparent;
        }
      }
    }
  }
}

.env-switch {
  text-align: center;

  span {
    display: block;
    font-size: 12px;
    margin-bottom: 8px;

    &.prod {
      color: #ff4949;
    }

    &.stage {
      color: #13ce66;
    }
  }
}

.user-actions {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;

  .user {
    padding: 9px 7px;
    color: rgb(201, 201, 201);
    margin-right: 10px;

    b {
      color: $ice;
    }
  }
}
</style>
