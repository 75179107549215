<template>
  <div id="app">
    <Menu />
    <Main />
  </div>
</template>

<script>
import Menu from './components/Menu'
import Main from './components/Main'

export default {
  name: 'App',
  components: {
    Menu,
    Main
  }
}
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
html, body {
  margin: 0;
  font-family: "Comfortaa", "Avenir", Helvetica, Arial, sans-serif;
}
#app {
  display: flex;
  justify-content: flex-start;
  height: 100vh;
  overflow: hidden;
}

.page {
  padding: 60px;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  background: white;

  h1 {
    color: $deepsea;

    .subtext {
      font-weight: normal;
    }
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>
