import { CHANGE_ENV } from "./actions";
import { FETCH_STATISTICS } from "../statistics/actions";
import { SET_ENV } from "./mutations";
import apiService from "../../../services/api";

const state = {
  env: 'staging'
};

const actions = {
  async [CHANGE_ENV](context, env) {
    context.commit(SET_ENV, env);
    apiService.setEnv(env);
    context.dispatch(FETCH_STATISTICS);
  }
};

const mutations = {
  [SET_ENV](state, env) {
    state.env = env;
  }
};

export default {
  state,
  actions,
  mutations
};
