export default {
  patchOffer: "SOCKET_EVENT_PATCH_OFFER",
  refetchDamage: "SOCKET_EVENT_REFETCH_DAMAGE",
  newDamageAdded: "SOCKET_EVENT_NEW_DAMAGE_ADDED",
  patchDamage: "SOCKET_EVENT_PATCH_DAMAGE",
  newNotification: "SOCKET_EVENT_NEW_NOTIFICATION",
  removedNotifications: "SOCKET_EVENT_REMOVED_NOTIFICATIONS",
  removedDamage: "SOCKET_EVENT_REMOVED_DAMAGE",
  removedOffer: "SOCKET_EVENT_REMOVED_OFFER"
};
