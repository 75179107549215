import axios from "axios";

const apiService = {
  url: process.env.VUE_APP_ADMIN_API_URL,
  _axios: null,
  authToken: null,
  env: 'staging',

  setEnv(env) {
    this.env = env;
  },

  init() {
    this.updateAxiosInstance();
  },

  async axios() {
    await this._updateAxiosInstance();
    return this._axios;
  },

  updateAxiosInstance(user) {
    this.user = user;
    return this._updateAxiosInstance();
  },

  async _updateAxiosInstance() {
    const newToken = this.user && (await this.user.getIdToken());
    if (this.authToken === newToken) {
      return;
    }
    this.authToken = newToken;
    this._axios = axios.create({
      baseURL: this.url,
      headers: this.authToken
        ? { authorization: `Bearer ${this.authToken}` }
        : undefined
    });
  },

  async getInvitedUsers() {
    const axios = await this.axios();
    return (await axios.get("users/invited", { params: { env: this.env } })).data;
  },

  async getProjects() {
    const axios = await this.axios();
    return (await axios.get("projects")).data;
  },

  async mergeDevelop(project) {
    const axios = await this.axios();
    return (await axios.post(`projects/${project}/merge-develop`)).data;
  },

  async getBankAccounts() {
    const axios = await this.axios();
    return (await axios.get("bank/accounts")).data;
  },

  async getStatistics() {
    const axios = await this.axios();
    return (await axios.get("statistics", { params: { env: this.env } })).data;
  },

  async getUsers(accountType, lastId, limit, search) {
    const axios = await this.axios();
    return (await axios.get("users", { params: { env: this.env, lastId, limit, accountType, search: search ? search : undefined } })).data;
  },

  async getMails(lastId, limit, search) {
    const axios = await this.axios();
    return (await axios.get("mails", { params: { env: this.env, lastId, limit, search: search ? search : undefined } })).data;
  },

  async getPayouts() {
    const axios = await this.axios();
    return (await axios.get("payouts", { params: { env: this.env } })).data;
  },

  async payoutPayed(id) {
    const axios = await this.axios();
    return (await axios.post("payouts/" + id + "/payed", null, { params: { env: this.env } })).data;
  },

  async rerenderInvoices(id) {
    const axios = await this.axios();
    return (await axios.post("damages/" + id + "/rerender-invoices", null, { params: { env: this.env } })).data;
  },

  async enableUser(userId) {
    const axios = await this.axios();
    return (await axios.post(`users/${userId}/unlock`, null, { params: { env: this.env } })).data;
  },

  async disableUser(userId) {
    const axios = await this.axios();
    return (await axios.post(`users/${userId}/lock`, null, { params: { env: this.env } })).data;
  },

  async sendPasswordMail(userId) {
    const axios = await this.axios();
    return (await axios.post(`users/${userId}/reset-password`, null, { params: { env: this.env } })).data;
  },

  async getUserToken(userId) {
    const axios = await this.axios();
    return (await axios.post(`users/${userId}/token`, null, { params: { env: this.env } })).data;
  },

  async getAppUrl() {
    const axios = await this.axios();
    return (await axios.get(`const/app`, { params: { env: this.env } })).data;
  },

  async uploadCarDatabase(file, dropAll) {
    const formData = new FormData();
    formData.append("db", file);
    const axios = await this.axios();
    return (
      await axios.post(`import/car`, formData, { params: { env: this.env, dropAll } })
    ).data;
  },

  async getDamageList({ sort, filter, lastId, limit } = {}) {
    const axios = await this.axios();
    const params = {
      env: this.env,
      sort: sort || undefined,
      filter: filter || undefined,
      lastId: lastId || undefined,
      limit: limit || undefined
    };
    return (await axios.get("damages/list", { params }))
      .data.damages;
  },

  async deleteDamage(damageId) {
    const axios = await this.axios();
    return (await axios.delete(`damages/${damageId}`, { params: { env: this.env } }))
      .data;
  },

  async getTemplate(templateKey) {
    const axios = await this.axios();
    return (await axios.get(`templates/${templateKey}`, { params: { env: this.env } }))
      .data;
  },

  async updateTemplate(templateKey, html, updateMessage) {
    const axios = await this.axios();
    return (await axios.post(`templates/${templateKey}`, { html, updateMessage }, { params: { env: this.env } }))
      .data;
  },

  async publishTemplate(templateKey) {
    const axios = await this.axios();
    return (await axios.post(`templates/${templateKey}/publish`, null, { params: { env: this.env } }))
      .data;
  },

  async pushTemplatesToDatabse(keys) {
    const axios = await this.axios();
    return (await axios.post(`templates/push-to-db`, { keys }, { params: { env: this.env } }))
      .data;
  },

  /**
   *
   * @param {string} html
   * @param {object} payload
   * @param {string} type "pdf" or "thumb"
   * @returns
   */
  async exportTemplate(html, payload, type) {
    const axios = await this.axios();
    return (await axios.post(`templates/export`, { html, payload, type }, { responseType: 'blob' }))
      .data;
  },

  async createUser({
    email,
    accountType,
    firstname,
    lastname,
    password,
  }) {
    const axios = await this.axios();
    return (
      await axios.post(`users`, {
        email,
        accountType,
        firstname,
        lastname,
        password,
      }, { params: { env: this.env } })
    ).data;
  },

};

export default apiService;
