<template>
    <div class="user-registered-chart-box"> 
      <div class="title">
          <fa-icon size="2x" icon="users" />
          <span class="text">Nutzer (Gesamt)</span>
          <span class="number">{{ total }} <i>( {{ werkstattTotal }} W  {{ customerTotal }} K )</i></span>
      </div>
      <div class="status">
        <Chart :width="500" :height="200" :chartdata="chartdata" />
      </div>
    </div>
</template>

<script>
import Chart from './Chart'
export default {
  name: 'UserRegisteredChartBox',
  props: ["data"],
  computed: {
    total() {
      return this.werkstattTotal + this.customerTotal;
    },
    werkstattTotal() {
      if (!this.data || !this.data.total) {
        return 0;
      }
      return this.data.total.ACCOUNT_TYPE_GARAGE;
    },
    customerTotal() {
      if (!this.data || !this.data.total) {
        return 0;
      }
      return this.data.total.ACCOUNT_TYPE_CUSTOMER;
    },
    chartdata() {
      if (!this.data.recent || !this.data.total) {
        return {};
      }
      return {
          labels: this.data.recent.map(e => e.date).reverse(),
          datasets: [
            {
              label: 'Werkstatt',
              backgroundColor: '#072c3d',
              data: this.data.recent.reduce((r, e) =>  ([...r, r[r.length - 1] - e.ACCOUNT_TYPE_GARAGE]), [this.werkstattTotal]).reverse().slice(1),
            }, {
              label: 'Kunde',
              backgroundColor: '#78bdc4',
              data: this.data.recent.reduce((r, e) => ([...r, r[r.length - 1] - e.ACCOUNT_TYPE_CUSTOMER]), [this.customerTotal]).reverse().slice(1),
            },
          ]
        };
    }
  },
  components: {
      Chart
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.user-registered-chart-box {
    box-sizing: border-boxw;
    width: 500px;
    height: 250px;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: 2px 1px 6px rgba(20, 20, 20, 0.1);
    border-radius: 19px;
    margin: 10px;
    padding: 15px;
    cursor: default;

    .title {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .text {
        display: block;
        margin: 0 20px 0 20px;
        overflow: hidden;
        font-size: 14px;
      }

      .number {
        position: absolute;
        right: 0;
        font-size: 16px;
        background: $deepsea;
        color: white;
        padding: 10px 20px;
        border-radius: 32px;

        i {
          color: rgba(255, 255, 255, 0.7);
          font-style: normal;
        }
      }
    }
}
</style>
