<template>
    <div class="damages-open-chart-box"> 
      <div class="title">
          <fa-icon size="2x" icon="car-crash" />
          <span class="text">Schäden Offen (Gesamt)</span>
          <span class="number">{{ totalOpen }}</span>
      </div>
      <div class="status">
        <Chart :width="500" :height="200" :chartdata="chartdata" />
      </div>
    </div>
</template>

<script>
import Chart from './Chart'

const readableDate = (dateString) => {
  const [month, day] = dateString.split("-").slice(1);
  return `${day}.${month}`;
}

export default {
  name: 'DamagesOpenChartBox',
  props: ["data"],
  computed: {
    totalOpen() {
      console.log(this.data);
      if (!this.data || !this.data.total) {
        return 0;
      }
      return this.data.total.open;
    },
    chartdata() {
      if (!this.data.recent || !this.data.total) {
        return {};
      }
      return {
          labels: this.data.recent.map(e => readableDate(e.date)).reverse(),
          datasets: [
            {
              label: 'Offen',
              backgroundColor: '#072c3d',
              borderColor: '#072c3d',
              fill: false,
              data: this.data.recent.reduce((r, e) =>  ([...r, r[r.length - 1] - e.created.total + e.done.total]), [this.totalOpen]).reverse().slice(1),
            },
            {
              label: 'Hinzugefügt',
              backgroundColor: '#78bdc4',
              borderColor: '#78bdc4',
              fill: false,
              data: this.data.recent.map(e =>  e.created.total).reverse(),
            },
            {
              label: 'Abgeschlossen',
              backgroundColor: '#e94650',
              borderColor: '#e94650',
              fill: false,
              data: this.data.recent.map(e =>  e.done.total).reverse(),
            },
          ]
        };
    }
  },
  components: {
      Chart
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.damages-open-chart-box {
    box-sizing: border-boxw;
    width: 500px;
    height: 250px;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: 2px 1px 6px rgba(20, 20, 20, 0.1);
    border-radius: 19px;
    margin: 10px;
    padding: 15px;
    cursor: default;

    .title {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .text {
        display: block;
        margin: 0 20px 0 20px;
        overflow: hidden;
        font-size: 14px;
      }

      .number {
        position: absolute;
        right: 0;
        font-size: 16px;
        background: $deepsea;
        color: white;
        padding: 10px 20px;
        border-radius: 32px;

        i {
          color: rgba(255, 255, 255, 0.7);
          font-style: normal;
        }
      }
    }
}
</style>
