import { FETCH_PROJECTS } from "./actions";
import { SET_PROJECTS, SET_ERROR, SET_FETCHING } from "./mutations";
import apiService from "../../../services/api";

const state = {
  isFetching: false,
  data: null,
  error: null
};

const actions = {
  async [FETCH_PROJECTS](context) {
    context.commit(SET_FETCHING, true);
    context.commit(SET_ERROR, null);
    try {
      context.commit(SET_PROJECTS, await apiService.getProjects());
    } catch (error) {
      context.commit(SET_ERROR, error);
    }
    context.commit(SET_FETCHING, false);
  }
};

const mutations = {
  [SET_FETCHING](state, status) {
    state.isFetching = status;
  },
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_PROJECTS](state, user) {
    state.data = {
      ...user
    };
  }
};

export default {
  state,
  actions,
  mutations
};
