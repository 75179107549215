import { FETCH_STATISTICS } from "./actions";
import { SET_STATISTICS, SET_ERROR, SET_FETCHING } from "./mutations";
import apiService from "../../../services/api";

const state = {
  isFetching: false,
  data: null,
  error: null
};

const actions = {
  async [FETCH_STATISTICS](context) {
    context.commit(SET_FETCHING, true);
    context.commit(SET_ERROR, null);
    try {
      context.commit(SET_STATISTICS, (await apiService.getStatistics()).statistics);
    } catch (error) {
      context.commit(SET_ERROR, error);
    }
    context.commit(SET_FETCHING, false);
  }
};

const mutations = {
  [SET_FETCHING](state, status) {
    state.isFetching = status;
  },
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_STATISTICS](state, data) {
    state.data = {
      ...data
    };
  }
};

export default {
  state,
  actions,
  mutations
};
