import Vue from 'vue'
import store from "../vuex/store";
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const getIsLoaded = state => state.auth.isLoaded;

const isLoaded = (to, from, next) => {
  if (getIsLoaded(store.state)) return next();
  const unWatch = store.watch(getIsLoaded, value => {
    unWatch();
    if (value) next();
  });
};

const needsAuth = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    return next("/login");
  }
  return next();
};

const redirectOnAuth = destination => (to, from, next) => {
  if (store.getters.isAuthenticated) {
    return next(destination);
  }
  return next();
};

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: needsAuth,
    component: Home
  },
  {
    path: '/payouts',
    name: 'Payouts',
    beforeEnter: needsAuth,
    component: () => import(/* webpackChunkName: "payouts" */ '../views/Payouts.vue')
  },
  {
    path: '/ship',
    name: 'Ship',
    beforeEnter: needsAuth,
    component: () => import(/* webpackChunkName: "ship" */ '../views/Ship.vue')
  },
  {
    path: '/templates',
    name: 'Templates',
    beforeEnter: needsAuth,
    component: () => import(/* webpackChunkName: "templatesCode" */ '../views/TemplatesCode.vue')
  },
  {
    path: '/users',
    name: 'Users',
    beforeEnter: needsAuth,
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    path: '/mails',
    name: 'Mails',
    beforeEnter: needsAuth,
    component: () => import(/* webpackChunkName: "Mails" */ '../views/Mails.vue')
  },
  {
    path: '/damages',
    name: 'Damages',
    beforeEnter: needsAuth,
    component: () => import(/* webpackChunkName: "damages" */ '../views/Damages.vue')
  },
  {
    path: '/links',
    name: 'Links',
    beforeEnter: needsAuth,
    component: () => import(/* webpackChunkName: "links" */ '../views/Links.vue')
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: redirectOnAuth('/'),
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(isLoaded);

export default router
