<template>
    <div class="user-registered-chart-box"> 
      <div class="title">
          <fa-icon size="2x" icon="car-crash" />
          <span class="text">Schäden Offen (Gesamt)</span>
          <span class="number">{{ totalOpen }}</span>
      </div>
      <div class="status">
        <Chart :width="400" :height="240" :chartdata="chartdata" />
      </div>
    </div>
</template>

<script>
import Chart from './Chart'

const colors = [ "#072C3D", "#78BDC4", "#W94650", "#BCE784", "#E5CDC8", "#FDE74C", "#646F4B", "#AB92BF", "#FFD8BE" ];
const labelText = (typeArr) => {
  return typeArr.map(t => {
    switch (t) {
      case "DAMAGE_TYPE_DENT":
        return "Delle";
      case "DAMAGE_TYPE_BUMP":
        return "Beule";
      case "DAMAGE_TYPE_SCRATCH":
        return "Kratzer";
      case "DAMAGE_TYPE_POLISH":
        return "Polieren";
      case "DAMAGE_TYPE_RIM":
        return "Felgen-Schaden";
    }
  }).join(', ');
};

export default {
  name: 'DamagesOpenDonutBox',
  props: ["data"],
  computed: {
    totalOpen() {
      console.log(this.data);
      if (!this.data || !this.data.total) {
        return 0;
      }
      return this.data.total.open;
    },
    chartdata() {
      if (!this.data.recent || !this.data.total) {
        return {};
      }
      return {
          labels: this.data.total.details.map((detail) => labelText(detail.damages)),
          datasets: [{
            data: this.data.total.details.map((detail) => detail.total - detail.accepted),
            backgroundColor: this.data.total.details.map((_, index) => colors[index % colors.length])
          }]
      };
    }
  },
  components: {
      Chart
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.user-registered-chart-box {
    box-sizing: border-box;
    width: 400px;
    height: 282px;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: 2px 1px 6px rgba(20, 20, 20, 0.1);
    border-radius: 19px;
    margin: 10px;
    padding: 15px;
    cursor: default;

    .title {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 7px;

      .text {
        display: block;
        margin: 0 20px 0 20px;
        overflow: hidden;
        font-size: 14px;
      }

      .number {
        position: absolute;
        right: 0;
        font-size: 16px;
        background: $deepsea;
        color: white;
        padding: 10px 20px;
        border-radius: 32px;

        i {
          color: rgba(255, 255, 255, 0.7);
          font-style: normal;
        }
      }
    }
}
</style>
