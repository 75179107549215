import { FETCH_USERS, LOAD_MORE_USERS, ENABLE_USER, DISABLE_USER, SEND_USER_PASSWORD_RESET_MAIL } from "./actions";
import { PUSH_USERS, SET_USERS, SET_ERROR, SET_FETCHING, SET_ACCOUNTTYPE, SET_LASTID, PATCH_USER, SET_SEARCH } from "./mutations";
import apiService from "../../../services/api";
import constants from "@/constants";

const state = {
  loading: false,
  search: '',
  error: null,
  accountType: constants.accountType.customer,
  lastId: null,
  limit: 10,
  items: []
};

const patches = {
  setDisabled: state => (item) => ({ ...item, _firebase: { ...item._firebase, disabled: state } }),
};

const actions = {
  async [LOAD_MORE_USERS](context) {
    context.commit(SET_FETCHING, true);
    try {
      const { users } = await apiService.getUsers(context.state.accountType, context.state.lastId, context.state.limit);
      context.commit(PUSH_USERS, users);
      context.commit(SET_LASTID, users[users.length - 1]._id);
    } catch (error) {
      context.commit(SET_ERROR, error);
    }
    context.commit(SET_FETCHING, false);
  },
  async [FETCH_USERS](context, { accountType, search }) {
    context.commit(SET_FETCHING, true);
    context.commit(SET_USERS, []);
    context.commit(SET_LASTID, null);
    context.commit(SET_ERROR, null);
    context.commit(SET_SEARCH, search);
    context.commit(SET_ACCOUNTTYPE, accountType);
    try {
      const { users } = await apiService.getUsers(accountType, context.state.lastId, context.state.limit, context.state.search);
      context.commit(SET_USERS, users);
      context.commit(SET_LASTID, users[users.length - 1]._id);
    } catch (error) {
      context.commit(SET_ERROR, error);
    }
    context.commit(SET_FETCHING, false);
  },
  async [ENABLE_USER](context, userId) {
    try {
      await apiService.enableUser(userId);
      context.commit(PATCH_USER, {
        id: userId,
        patch: patches.setDisabled(false)
      });
    } catch (error) {
      return;
    }
  },
  async [DISABLE_USER](context, userId) {
    try {
      await apiService.disableUser(userId);
      context.commit(PATCH_USER, {
        id: userId,
        patch: patches.setDisabled(true)
      });
    } catch (error) {
      return;
    }
  },
  async [SEND_USER_PASSWORD_RESET_MAIL](context, userId) {
    try {
      await apiService.sendPasswordMail(userId);
    } catch (error) {
      return;
    }
  },
};

const mutations = {
  [SET_FETCHING](state, status) {
    state.loading = status;
  },
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_USERS](state, users) {
    state.items = users;
  },
  [PUSH_USERS](state, users) {
    state.items = [...state.items, ...users];
  },
  [SET_ACCOUNTTYPE](state, accountType) {
    state.accountType = accountType;
  },
  [SET_SEARCH](state, search) {
    state.search = search;
  },
  [SET_LASTID](state, lastId) {
    state.lastId = lastId;
  },
  [PATCH_USER](state, { id, patch }) {
    state.items = state.items.map(item => {
      if (item._id === id) {
        return patch(item);
      }
      return item;
    }).filter(x => x);
  },
};

export default {
  state,
  actions,
  mutations
};
