import { FETCH_PAYOUTS, LOAD_MORE_PAYOUTS } from "./actions";
import { SET_PAYOUTS, SET_ERROR, SET_FETCHING } from "./mutations";
import apiService from "../../../services/api";

const state = {
  loading: false,
  error: null,
  open: {},
  history: {},
};

const actions = {
  async [LOAD_MORE_PAYOUTS](context) {
    context.commit(SET_FETCHING, true);
    try {
      const { payouts } = await apiService.getPayouts();
      context.commit(SET_PAYOUTS, payouts);
    } catch (error) {
      context.commit(SET_ERROR, error);
    }
    context.commit(SET_FETCHING, false);
  },
  async [FETCH_PAYOUTS](context) {
    context.commit(SET_FETCHING, true);
    context.commit(SET_PAYOUTS, []);
    context.commit(SET_ERROR, null);
    try {
      const { payouts } = await apiService.getPayouts();
      context.commit(SET_PAYOUTS, payouts);
    } catch (error) {
      context.commit(SET_ERROR, error);
    }
    context.commit(SET_FETCHING, false);
  },
};

const mutations = {
  [SET_FETCHING](state, status) {
    state.loading = status;
  },
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_PAYOUTS](state, payouts) {
    state.open = payouts.open;
    state.history = payouts.history;
  },
};

export default {
  state,
  actions,
  mutations
};
