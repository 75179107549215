import Store from "../vuex/store";
import Router from "../router";
//import SocketService from "./socket";
import { AUTH_CHANGE, UPDATE_AUTH_USER } from "../vuex/modules/auth/actions";
import { FETCH_PROJECTS } from '../vuex/modules/projects/actions'
import { FETCH_STATISTICS } from '../vuex/modules/statistics/actions'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';

import { firebaseApp } from '../firebase';

const UPDATE_PROJECTS_INTERVAL = 10000;
let updateProjectsIntervalId = null;

const authService = {
  app: null,

  init() {
    this.app = firebaseApp;
    getAuth(this.app).onAuthStateChanged(async user => {
      Store.dispatch(AUTH_CHANGE, user);
      if (user) {
        //SocketService.connect(user);
        Store.dispatch(FETCH_STATISTICS);
        Store.dispatch(FETCH_PROJECTS);

        updateProjectsIntervalId = setInterval(() => {
          this.updateProjectsInterval();
        }, UPDATE_PROJECTS_INTERVAL);

      } else {
        //SocketService.disconnect();
        if (updateProjectsIntervalId) clearInterval(updateProjectsIntervalId);
      }
    });
  },

  updateProjectsInterval() {
    if (Router.currentRoute.name === "Home") {
      Store.dispatch(FETCH_PROJECTS);
    }
  },

  getUser() {
    return Store.getters.authUser;
  },

  updateUserState() {
    Store.dispatch(UPDATE_AUTH_USER, getAuth(this.app).currentUser);
  },

  async changeEmail(email) {
    const user = this.getUser();
    await user.updateEmail(email);
    this.updateUserState();
  },

  async changePassword(password) {
    const user = this.getUser();
    await user.updatePassword(password);
    this.updateUserState();
  },

  reAuthenticate(password) {
    const user = this.getUser();
    const credentials = this.app.auth.EmailAuthProvider.credential(
      user.email,
      password
    );
    return user.reauthenticateWithCredential(credentials);
  },

  login(email, password) {
    return signInWithEmailAndPassword(getAuth(this.app), email, password);
  },

  register(email, password) {
    return createUserWithEmailAndPassword(getAuth(this.app), email, password);
  },

  logout() {
    Store.dispatch(AUTH_CHANGE, null);
    return getAuth(this.app).signOut();
  }
};

export default authService;
