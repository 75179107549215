<template>
    <div @click="open" class="project-box"> 
      <div class="title">
          <fa-icon size="2x" :icon="['fab', 'github']" />
          <span class="text">{{ project.name }}</span>
      </div>
      <div class="status">
        <el-popover
          v-if="prodHosting"
          :title="hostingTitle"
          width="250"
          trigger="hover"
          :content="hostingContent">
          <span slot="reference" :class="{ 'item': 1, 'warning': !hostingOnline }">
            <fa-icon icon="server" />
          </span>
        </el-popover>

        <el-popover
          v-if="ciStatus"
          :title="ciStatus"
          width="200"
          trigger="hover"
          content="current prod-ci status">
          <span slot="reference" :class="{ 'item': 1, [ciStatus]: 1 }">
            <fa-icon icon="dolly" />
          </span>
        </el-popover>

        <el-popover
          :title="project.dirtyProd ? 'dirty' : 'clean'"
          width="250"
          trigger="hover"
          content="it is clean if prod equals stage">
          <span slot="reference" :class="{ 'item': 1, 'warning': project.dirtyProd }">
            <fa-icon v-if="project.dirtyProd" icon="low-vision" />
            <fa-icon v-else icon="eye" />
          </span>
        </el-popover>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ProjectBox',
  props: ["project"],
  computed: {
    ciStatus() {
      return this.project.prodCiStatus && this.project.prodCiStatus.status;
    },
    prodHosting() {
      return this.project.prodHosting;
    },
    hostingOnline() {
      if (this.prodHosting && this.prodHosting.service === "heroku") {
        return this.prodHosting.dynos.filter(d => d.state === "up").length > 0;
      }
      return false;
    },
    hostingTitle() {
      console.log(this.prodHosting)
      if (this.prodHosting && this.prodHosting.service === "heroku") {
        return this.hostingOnline ? "Online on Heroku" : "Offline on Heroku";
      }
      return "";
    },
    hostingContent() {
      if (this.prodHosting && this.prodHosting.service === "heroku") {
        return this.prodHosting.dynos.map(d => {
          return ` - ${d.name} | ${d.size} | ${d.state} | v${d.release.version}`
        }).join('<br>');
      }
      return "";
    }
  },
  methods: {
    open() {
      window.open(this.project.link, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.project-box {
    box-sizing: border-boxw;
    width: 170px;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: 2px 1px 6px rgba(20, 20, 20, 0.1);
    border-radius: 19px;
    margin: 10px;
    padding: 15px;
    cursor: pointer;

    .status {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid rgb(230, 230, 230);
      margin: 10px 0 0 0;
      padding: 10px 0 0 0;

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(112, 175, 112);
        color: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0 0 0 10px;
        font-size: 16px;

        &.warning { 
          background: rgb(230, 77, 77);
        }

        &.running, &.queued, &.not_running {
          animation: pulse-animation 2s infinite;
          background: #6aa5e2;
        }
      }
    }

    .title {
      position: relative;
      display: flex;
      align-items: center;

      .text {
        display: block;
        margin: 0 20px 0 20px;
        overflow: hidden;
        font-size: 14px;
      }
    }
}
</style>
