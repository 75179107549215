<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: ['chartdata'],
  data() {
      return {
          options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
          }
      }
  },
  watch: {
    chartdata() {
      this.renderChart(this.chartdata, this.options);
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options);
  }
}
</script>
