<template>
    <div @click="open" class="balance-box"> 
      <div class="title">
          <fa-icon size="2x" icon="coins" />
          <span class="text">{{ `${bankAccount.name || "Bank Account"} (${bankAccount.currency})` }}</span>
      </div>
      <div class="balance">
        {{ bankAccount.balance }}
      </div>
    </div>
</template>

<script>
export default {
  name: 'BalanceBox',
  props: ["bankAccount"],
  mounted() {
    console.log(this.bankAccount);
  },
  methods: {
    open() {
      window.open(this.project.link, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.balance-box {
    box-sizing: border-boxw;
    width: 240px;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: 2px 1px 6px rgba(20, 20, 20, 0.1);
    border-radius: 19px;
    margin: 10px;
    padding: 15px;
    cursor: pointer;

    .balance {
      display: flex;
      justify-content: center;
      margin: 10px 0 0 0;
      padding: 20px 0;
      font-size: 22px;
      background: #696969;
      color: white;
      border-radius: 12px;
      box-shadow: 2px 4px 12px rgba(110, 110, 110, 0.4);
    }

    .title {
      position: relative;
      display: flex;
      align-items: center;

      .text {
        display: block;
        margin: 0 20px 0 20px;
        overflow: hidden;
        font-size: 14px;
      }
    }
}
</style>
