<template>
  <div class="page" ref="page">

    <button class="fs-btn" @click="openFullscreen">
      <fa-icon size="2x" :icon="isFullscreen ? 'compress' : 'expand'" />
    </button>

    <div v-if="revolutCode && false" class="section">
      <h2>Banking</h2>
      <div class="boxes">
        <BalanceBox v-for="bAcc in bankAccounts" :bankAccount="bAcc" :key="bAcc.id" />
      </div>
    </div>

    <div class="section">
      <h2>Statistics</h2>
      <div class="boxes">
        <UserRegisteredChartBox :data="userStatistics" />
        <DamagesOpenChartBox :data="damageStatistics" />
        <DamagesOpenDonutBox :data="damageStatistics" />
      </div>
    </div>

    <div class="section">
      <h2>Apps</h2>
      <div class="boxes">
        <ProjectBox v-for="project in projects" :project="project" :key="project.name" />
      </div>
    </div>

  </div>
</template>

<script>
import BalanceBox from '../components/BalanceBox';
import ProjectBox from '../components/ProjectBox';
import UserRegisteredChartBox from '../components/UserRegisteredChartBox';
import DamagesOpenChartBox from '../components/DamagesOpenChartBox';
import DamagesOpenDonutBox from '../components/DamagesOpenDonutBox';
import { mapState } from "vuex";

export default {
  name: 'Home',
  data() {
    return {
      isFullscreen: false
    };
  },
  computed: {
    ...mapState({
      statistics: state => state.statistics.data,
      bankAccounts: state => state.bankAccounts.data,
      projects: state => state.projects.data,
    }),
    userStatistics() {
      return this.statistics ? this.statistics.users : {};
    },
    damageStatistics() {
      return this.statistics ? this.statistics.damages : {};
    }
  },
  mounted() {
    this.$refs.page.addEventListener('fullscreenchange', () => {
      this.isFullscreen = this.$refs.page === document.fullscreenElement;
    });
  },
  methods: {
    openRevolutAuth() {
      window.location.href = process.env.VUE_APP_REVOLUT_APP_CONFIRM_URL;
    },
    openFullscreen() {
      if (!this.isFullscreen) {
        this.$refs.page.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  },
  components: {
    ProjectBox,
    BalanceBox,
    UserRegisteredChartBox,
    DamagesOpenChartBox,
    DamagesOpenDonutBox
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.boxes {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.section {

  h2 {
    color: rgb(61, 61, 61);
  }
}

.notify-wrap {
  display: flex;
  justify-content: flex-start;
}

.fs-btn {
  border: none;
  background: transparent;
  color: rgb(189, 189, 189);
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  outline: none;

  &:hover {
    color: $ice;
  }
}
</style>
