import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import damageList from "./modules/damage-list";
import projects from "./modules/projects";
import statistics from "./modules/statistics";
import bankAccounts from "./modules/bank-accounts";
import settings from "./modules/settings";
import users from "./modules/users";
import mails from "./modules/mails";
import template from "./modules/template";
import payouts from "./modules/payouts";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    projects,
    statistics,
    bankAccounts,
    settings,
    users,
    mails,
    damageList,
    template,
    payouts,
  }
});
