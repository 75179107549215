import { FETCH_MAILS, LOAD_MORE_MAILS } from "./actions";
import { PUSH_MAILS, SET_MAILS, SET_ERROR, SET_FETCHING, SET_LASTID, SET_SEARCH } from "./mutations";
import apiService from "../../../services/api";

const state = {
  loading: false,
  search: '',
  error: null,
  lastId: null,
  limit: 10,
  items: []
};

const actions = {
  async [LOAD_MORE_MAILS](context) {
    context.commit(SET_FETCHING, true);
    try {
      const { mails } = await apiService.getMails(context.state.lastId, context.state.limit, context.state.search);
      context.commit(PUSH_MAILS, mails);
      context.commit(SET_LASTID, mails[mails.length - 1]._id);
    } catch (error) {
      context.commit(SET_ERROR, error);
    }
    context.commit(SET_FETCHING, false);
  },
  async [FETCH_MAILS](context, { search }) {
    context.commit(SET_FETCHING, true);
    context.commit(SET_MAILS, []);
    context.commit(SET_LASTID, null);
    context.commit(SET_ERROR, null);
    context.commit(SET_SEARCH, search);
    try {
      const { mails } = await apiService.getMails(context.state.lastId, context.state.limit, context.state.search);
      context.commit(SET_MAILS, mails);
      context.commit(SET_LASTID, mails[mails.length - 1]._id);
    } catch (error) {
      context.commit(SET_ERROR, error);
    }
    context.commit(SET_FETCHING, false);
  },
};

const mutations = {
  [SET_FETCHING](state, status) {
    state.loading = status;
  },
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_MAILS](state, mails) {
    state.items = mails;
  },
  [PUSH_MAILS](state, mails) {
    state.items = [...state.items, ...mails];
  },
  [SET_SEARCH](state, search) {
    state.search = search;
  },
  [SET_LASTID](state, lastId) {
    state.lastId = lastId;
  },
};

export default {
  state,
  actions,
  mutations
};
