<template>
    <div class="main">
      <router-view/>
    </div>
</template>

<script>
export default {
  name: 'Main',
  components: {}
}
</script>

<style lang="scss" scoped> 
.main {
  background: white;
  flex-grow: 1;
}
</style>
